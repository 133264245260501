import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import MTF from "./manual_tag_form";

export default function ModalManualTagForm({
  isOpen,
  toggle,
  onSubmit,
  defaultAction,
  defaultPart,
  equipmentType
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader>Manual Tag</ModalHeader>
      <ModalBody>
        <div className="container">
          <MTF
            onCancel={toggle}
            onSubmit={onSubmit}
            defaultAction={defaultAction}
            defaultPart={defaultPart}
            equipmentType={equipmentType}
          />
        </div>
      </ModalBody>
    </Modal>
  );
}
