import React, { Component } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";
import PasswordUpdater from "./password_updater";

class ChangePassword extends Component {
  state = {
  };

  render() {
    return (
      <Card>
        <CardBody>
          <Container fluid>
            <Row>
              <Col>
                <PasswordUpdater />
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    );
  }
}

export default ChangePassword;
