import fuzzysearch from "fuzzysearch";

export default (filter, row) => {
  // if string starts with ~
  //exact match only removing the ~
  if (filter.value.startsWith("~")) {
    return (
      String(row[filter.id]).toLowerCase().includes(filter.value.substr(1).toLowerCase())
    );
  }

  if (filter.value.startsWith("=")) {
    return (
        String(row[filter.id]).toLowerCase() === filter.value.substr(1).toLowerCase()
    );
  }

  if (filter.value.startsWith("@")) {
    return (
        String(row[filter.id]).toLowerCase().startsWith(filter.value.substr(1).toLowerCase())
    );
  }

  //if string starts with ! invert normal filter
  if (filter.value.startsWith("!")) {
    return !fuzzysearch(
      filter.value.substr(1).toLowerCase(),
      String(row[filter.id]).toLowerCase()
    );
  }

  return fuzzysearch(
    filter.value.toLowerCase(),
    String(row[filter.id]).toLowerCase()
  );
};

export function round(value, digits) {
  const mul = Math.pow(10, digits);
  return Math.round(value * mul) / (1.0 * mul);
}

export function calcPnl({
  market_per_unit_value,
  priced_per_unit_value,
  priced_position,
  plug
}) {
  return calc_pnl(
    parseFloat(market_per_unit_value || 0),
    parseFloat(priced_per_unit_value || 0),
    parseFloat(priced_position || 0),
    parseFloat(plug || 0)
  );
}

function calc_pnl(market, priced, position, plug) {
  if (market != null && priced != null && position != null && plug != null)
    return round((market - priced) * position * 42 + plug, 2);
  return 0.0;
}

export function ZNFloat(value) {
  return parseFloat(value || 0);
}
