import React, { Component } from "react";
import {
  Alert,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback
} from "reactstrap";
import api from "../utils/api";


export default class PasswordUpdater extends Component {
  state = {
    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
    failedSave: false
  };

  passwordValid = () =>
    this.state.newPassword1 === this.state.newPassword2 &&
    this.state.newPassword1 &&
    this.state.newPassword1.length >= 8;

  invalidPasswordReason = () => {
    if (this.state.newPassword1 !== this.state.newPassword2)
      return "Passwords don't match";
    if (this.state.newPassword1.length < 8) return "Password not long enough";
  };

  updatePassword = () => {
    api
      .updatePassword(this.state.oldPassword, this.state.newPassword1)
      .then(res =>
        this.setState({
          failedSave: !res.data,
          newPassword1: "",
          newPassword2: "",
          oldPassword: ""
        })
      );
  };

  handleChange = e => {
    const key = e.target.id;
    const value = e.target.value;
    this.setState({ [key]: value });
  };

  render() {
    return (
      <div>
        <br />
        <h5>Update Password</h5>

        {this.state.failedSave && (
          <Alert color="danger">Updating Password Failed</Alert>
        )}
        <FormGroup>
          <Label for="oldPassword">Current Password</Label>
          <Input
            type="password"
            name="password"
            id="oldPassword"
            placeholder="Your Current Password"
            onChange={this.handleChange}
            value={this.state.oldPassword}
          />
        </FormGroup>
        <FormGroup>
          <Label for="newPassword1">New Password</Label>
          <Input
            type="password"
            name="password"
            id="newPassword1"
            placeholder="New Password"
            onChange={this.handleChange}
            value={this.state.newPassword1}
          />
        </FormGroup>
        <FormGroup>
          <Label for="newPassword2">Repeat New Password</Label>
          <Input
            type="password"
            name="password"
            id="newPassword2"
            placeholder="New Password"
            onChange={this.handleChange}
            value={this.state.newPassword2}
            invalid={!this.passwordValid()}
          />
          {!this.passwordValid() && (
            <FormFeedback>{this.invalidPasswordReason()}</FormFeedback>
          )}
        </FormGroup>
        <Button onClick={this.updatePassword} disabled={!this.passwordValid()}>
          Update Password
        </Button>
      </div>
    );
  }
}