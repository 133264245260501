import Autosuggest from "react-autosuggest";
import React from "react";
import { FormGroup, Label } from "reactstrap";

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
function getSuggestionValue(suggestion) {
  return suggestion.master_english;
}

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => <div>{suggestion.master_english}</div>;

export default class SuggestedField extends React.Component {
  state = {
    value: "",
    suggestions: []
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.props.options.filter(
          tag =>
            tag.master_english.toLowerCase().slice(0, inputLength) ===
            inputValue
        );
  };
  onChange = (event, { newValue }) => {
    this.props.onSugChange && this.props.onSugChange(newValue);
    // this.setState({
    //   value: newValue
    // });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { suggestions } = this.state;
    const inputProps = {
      placeholder: this.props.placeholder,
      value: this.props.value || "",
      onChange: this.onChange
    };
    return (
      <FormGroup>
        <Label>{this.props.label}</Label>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </FormGroup>
    );
  }
}
