import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import fuzzyFilter from "../util";
import api from "../utils/api";
import { Button } from "reactstrap";
import MTF from "./modal_manual_tag_form";
import {
    Card,
    CardBody,
    CardTitle,
    CardText,
    Container,
    Row,
    Col,
    Input
} from "reactstrap";
import cnf from "./cell_number_formatter";
import { TextField } from "../utils/forms";
import moment from 'moment';

function sumTrue(obj) {
    var sum = 0;
    for (var el in obj) {
        if (obj.hasOwnProperty(el) && obj[el]) {
            sum += 1;
        }
    }
    return sum;
}

function numericFilter(filter, row){
    if(filter.value.startsWith('>')){
        const value = parseFloat(filter.value.substr(1));
        if(isNaN(value)) return true;
        return row[filter.id] > value;
    }
    if(filter.value.startsWith('<')){
        const value = parseFloat(filter.value.substr(1));
        if(isNaN(value)) return true;
        return row[filter.id] < value;
    }
    return fuzzyFilter(filter, row);
}



class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: true,
            current_id: 0,
            show_modal: false,
            defaultAction: "",
            defaultPart: "",
            selected: {},
            selectAll: 0,
            sql_filter: "",
            selectedEquipmentTypes: [],
            oldestTicketDate: moment().subtract(500, 'years').calendar(),
            filterTicketsByDate: false
        };
    }
    
    componentDidMount() {
        var placeholderDate = null
        api.get_config().then( res => {
            this.setState({
                filterTicketsByDate: res.data['oldest_ticket_filter'],
                oldestTicketDate: res.data['oldest_ticket_filter'] ? moment().subtract(60, 'days').calendar() : moment().subtract(500, 'years').calendar()
            })
            placeholderDate = res.data['oldest_ticket_filter'] ? moment().subtract(60, 'days').calendar() : moment().subtract(500, 'years').calendar()
        }).then(() => {
            this.loadData(placeholderDate);
        })
        api.request_env().then(res => {
            this.setState({show_component1_action1: res.data})
        })
    }

    loadData = (oldestTicketDate) => {
        if(!oldestTicketDate)
            return
        this.setState({ loading: true });
        api.tickets(oldestTicketDate).then(result => {
            this.setState({ data: result.data, loading: false });
        });
    };

    onHandleOldestTicketAgeChange = (e) => {
        this.setState({oldestTicketDate: e.target.value});
        this.loadData(e.target.value);
    };

    clearSelected = () => {
        this.setState({ selected: {} });
    };

    filterData = sql_filter => {
        api.filtered_tickets(sql_filter).then(result => {
            this.setState({ data: result.data });
        });
    };

    manualTag = ({ id, action, part, equipment_type }) => {
        this.setState({
            current_id: id,
            mode: "single manual",
            show_modal: true,
            defaultAction: action,
            defaultPart: part,
            equipmentType: equipment_type
        });
    };

    bulkTag = ({ id }) => {
        this.setState({
            current_id: id,
            mode: "bulk manual",
            show_modal: true,
            defaultAction: "",
            defaultPart: "",
            equipmentType: ""
        });
    };

    saveTag = (action, part, equipmentType) => {
        if (this.state.mode === "single manual") {
            api
                .manual_tag_one(
                    this.state.current_id,
                    action,
                    part,
                    this.state.equipmentType
                )
                .then(() => {
                    this.loadData(this.state.oldestTicketDate);
                    this.setState({ show_modal: false, selected: {} });
                });
        }
        if (this.state.mode === "bulk manual") {
            api
                .manual_tag_bulk(
                    Object.keys(this.state.selected),
                    action,
                    part,
                    this.state.equipmentType
                )
                .then(() => {
                    this.loadData(this.state.oldestTicketDate);
                    this.setState({ show_modal: false, selected:{}});
                });
        }
    };

    toggleRow = (id, equipmentType) => {
        const newSelected = Object.assign({}, this.state.selected);
        const newSelectedEquipmentTypes = [...this.state.selectedEquipmentTypes];
        const newState = !this.state.selected[id];
        newSelected[id] = newState;
        if (newState && !newSelectedEquipmentTypes.includes(equipmentType)) {
            if (newSelectedEquipmentTypes.length) return;
            newSelectedEquipmentTypes.push(equipmentType);
        }

        if (!newState && sumTrue(newSelected) === 0) {
            newSelectedEquipmentTypes.length = 0;
        }

        this.setState({
            selected: newSelected,
            selectedEquipmentTypes: newSelectedEquipmentTypes
        });
    };

    getCol = () => {
        var col = [
            {
                accessor: "",
                Cell: ({ original }) => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selected[original.id] === true}
                            onChange={() =>
                                this.toggleRow(original.id, original.equipment_type)
                            }
                        />
                    );
                },
                show: api.permission_level('super_user'),
                sortable: false,
                filterable: false,
                id: "checkbox",
                width: 45
            },
            {
                Header: this.props.isFrench ? "Bon de travail" : "Ticket",
                accessor: "order_number",
                width: 120
            },
            {
                Header: this.props.isFrench ? "Équipement" : "Equipment",
                accessor: "equipment_id",
                width: 120
            },
            {
                Header: this.props.isFrench ? "Type" : "Type",
                accessor: "equipment_type",
                width: 100
            },
            {
                Header: this.props.isFrench ? "Équipe" : "Team",
                accessor: "team",
                width: 80
            },
            {
                Header: "Status Comment",
                accessor: "status_comment",
                width: 120
            },
            {
                Header: "Description 1",
                accessor: "description1",
                minWidth: 240
            },
            {
                Header: "Description 2",
                accessor: "description2",
                minWidth: 240
            },
            {
                Header: this.props.isFrench ? "Équipe desc" : "Team Desc",
                accessor: "manager_1"
            },
            {
                Header: "Component 1",
                accessor: "wo_component_1"
            },
            {
                Header: "Action 1",
                accessor: "wo_action_1"
            },
            {
                Header: this.props.isFrench ? "Heures" : "Hours",
                accessor: "hours",
                Cell: cnf.reg,
                className: "right",
                width: 80,
                filterMethod: numericFilter
            },
            {
                Header: this.props.isFrench ? "Coût Mat." : "Mat. Cost",
                accessor: "material_cost",
                Cell: cnf.dol,
                className: "right",
                width: 100,
                filterMethod: numericFilter
            },
            {
                Header: this.props.isFrench ? "Coût Maint" : "Maint Cost",
                accessor: "maintenance_cost",
                Cell: cnf.dol,
                className: "right",
                width: 100,
                filterMethod: numericFilter
            },
            {
                Header: "WO Type",
                accessor: "work_order_type",
                Cell: cnf.reg,
                width: 75,
                className: "right"
            },
            {
                Header: this.props.isFrench
                    ? "Date du Billet"
                    : "Ticket Date",
                accessor: "ticket_date"
            },
            {
                Header: this.props.isFrench ? "Intervention" : "Action",
                accessor: this.props.isFrench ? "french_action" : "action"
            },
            {
                Header: this.props.isFrench ? "Composante" : "Part",
                accessor: this.props.isFrench ? "french_part" : "part"
            },
            {
                Header: this.props.isFrench ? "Statut" : "State",
                accessor: "tag_state",
                width: 100
            },
            {
                show:api.permission_level('admin'),
                Header: this.props.isFrench ? "Catégoriser" : "Actions",
                Cell: row => {
                    return (
                        <div>
                            <ActionButton
                                label="AT"
                                onClick={() =>
                                    api
                                        .retag(row.original.id)
                                        .then(() => this.loadData(this.state.oldestTicketDate))
                                }
                                disabled={row.original.tag_state === "manual"}
                            />
                            <ActionButton
                                label="Man"
                                onClick={() => this.manualTag(row.original)}
                            />
                        </div>
                    );
                }
            }
        ]
        if(!this.state.show_component1_action1) {
            col = col.filter(column => column.accessor !== "wo_component_1" && column.accessor !== "wo_action_1")
        }
        return col
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col">
                        <Card>
                            <Container fluid>
                                <Row>
                                    <Col className="one-em-padding">
                                        {this.props.isFrench
                                            ? "Recherche annexe"
                                            : "Media Object Filter"}
                                    </Col>
                                    <MediaFilter filterData={this.filterData} />
                                    <Col className="right one-em-padding">
                                        {api.permission_level('super_user') &&
                                        <Button size="sm" onClick={this.bulkTag} className="right">
                                            Bulk Tag
                                        </Button>
                                        }
                                    </Col>
                                    {this.state.filterTicketsByDate && 
                                        <Col>
                                            {this.props.isFrench ? "Le plus ancien billet" : "Oldest Ticket Age"}
                                            <Input
                                                placeholder={this.state.oldestTicketDate}
                                                onChange={this.onHandleOldestTicketAgeChange}
                                                type="date"
                                                id="oldestDatePicker"
                                                defaultValue={this.state.oldestTicketDate && moment(this.state.oldestTicketDate).toISOString().substr(0, 10)}
                                            />
                                        </Col>
                                    }
                                </Row>
                            </Container>
                            <ReactTable
                                data={this.state.data}
                                loading={this.state.loading}
                                filterable
                                defaultFilterMethod={fuzzyFilter}
                                defaultPageSize={15}
                                columns={this.getCol()}
                                className="details -striped -highlight"
                                SubComponent={row => <SubCom id={row.original.id} />}
                            />
                        </Card>
                        <MTF
                            isOpen={this.state.show_modal}
                            toggle={() => this.setState({ show_modal: false })}
                            onCancel={() => this.setState({ show_modal: false })}
                            onSubmit={this.saveTag}
                            defaultAction={this.state.action || ""}
                            defaultPart={this.state.part || ""}
                            equipmentType={this.state.equipmentType}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Home);

function ActionButton({ label, onClick, disabled }) {
    return (
        <Button
            size="sm"
            onClick={onClick}
            style={{ marginLeft: "0.1em" }}
            disabled={disabled}
        >
            {label}
        </Button>
    );
}

class SubCom extends Component {
    state = {
        description1: "",
        media_objects: ""
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        api.ticket_details(this.props.id).then(res => {
            const { description1, media_objects } = res.data[0];
            this.setState({ description1, media_objects });
        });
    };

    render() {
        return (
            <div className="one-em-padding">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>Media Objects</CardTitle>
                                    <CardText className="scroll-div">
                                        <pre>{this.state.media_objects}</pre>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

class MediaFilter extends Component {
    state = {
        sql_filter: ""
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        return (
            <>
                <Col xs="8">
                    <TextField
                        id="sql_filter"
                        value={this.state.sql_filter}
                        onChange={this.handleChange}
                    />
                </Col>
                <Col className="right one-em-padding">
                    <Button
                        size="sm"
                        onClick={() => this.props.filterData(this.state.sql_filter)}
                        className="right"
                    >
                        Filter
                    </Button>
                </Col>
            </>
        );
    }
}
