import React, { Component } from "react";
import Nav from "./nav";
import Home from "./home";
import Admin from "./admin";
import api from "../utils/api";
import LoginForm from "./loginform";
import { Router, Route, Switch, Redirect, withRouter } from "react-router-dom";
import PBI from "./power_bi";
import { createBrowserHistory as createHistory } from "history";
import ActionTab from "./actions_tab";
import PartsTab from "./parts_tab";
import TagTest from "./tag_test";
import ChangePassword from "./change_password";
import { JsonWebTokenError } from "jsonwebtoken";

const navLinks = [
  { label: "Ticket Admin", labelf: "BT Admin", dest: "home", path: "/" },
  {
    label: "Ticket Analysis",
    labelf: "BT Analyse",
    dest: "powerbi",
    path: "/powerbi"
  },
  {
    label: "Actions",
    labelf: "Intervention",
    dest: "actions",
    path: "/actions"
  },
  { label: "Parts", labelf: "Composante", dest: "parts", path: "/parts" },
  { label: "Tag Testing", labelf: "Tester Catégorie", dest: "tag_test", path: "/tag_test" },
  { label: "Admin", labelf: "Admin", dest: "admin", path: "/admin" }
];

const suNavLinks = [
  { label: "Ticket Admin", labelf: "BT Admin", dest: "home", path: "/" },
  { label: "Ticket Analysis", labelf: "BT Analyse", dest: "powerbi", path: "/powerbi" },
  { label: "Actions", labelf: "Intervention", dest: "actions", path: "/actions" },
  { label: "Parts", labelf: "Composante", dest: "parts", path: "/parts" }
];

const roNavLinks = [
  { label: "Ticket Admin", labelf: "BT Admin", dest: "home", path: "/" },
  { label: "Ticket Analysis", labelf: "BT Analyse", dest: "powerbi", path: "/powerbi" }
];

class Main extends Component {
  state = {
    loggedIn: api.is_logged_in(),
    user: api.logged_in_user(),
    role: api.logged_in_role(),
    message: "",
    details: {
      risk_product_portfolio: null,
      position_type: null,
      risk_product_grouping: null
    },
    loaded: {
      last_load_date: "1/1/1900"
    },
    isFrench: true,
    filterTicketsByDate: false,
    useSaml: true
  };

  constructor(props) {
    super(props);
    api.set_handleLogout(this.handleLogout);
  }

  history = createHistory(this.props);

  componentDidMount() {
    console.log("Main did Mount");
    api.get_config().then(res => {
      console.log(res);
      this.setState({
        filterTicketsByDate: res.data["oldest_ticket_filter"],
        overrideLanguage: res.data["override_language"],
        isFrench: res.data["override_language"] === "false" ? true : false,
        useSaml: res.data["use_saml"] === "false" ? false : true
      });
      console.log("useSaml");
      console.log(this.useSaml);
    });
  }

  handleLogin = data => {
    if (this.state.useSaml) this.handleSamlLogin();
    else this.handleLegacyLogin(data);
  };

  handleSamlLogin = () => {
    window.location = api.base_url.concat("saml_login");
  };

  setUser = (user, role, loggedIn) => {
    const message = loggedIn ? "" : "Failed logging in";
    if (loggedIn) this.history.push("/");
    this.setState({
      user: user,
      role: role,
      loggedIn: loggedIn,
      message: message
    });
  };

  handleLegacyLogin = data => {
    console.log(data.user);
    api.authenticate(data).then(isLoggedIn => {
      const message = isLoggedIn ? "" : "Failed logging in";
      if (isLoggedIn) this.history.push("/");
      this.setState({
        user: data.user,
        role: api.logged_in_role(),
        loggedIn: isLoggedIn,
        message: message
      });
    });
  };

  getNavLinks = role => {
    if (role === "admin") {
      return navLinks;
    } else if (role === "super_user") {
      return suNavLinks;
    } else return roNavLinks;
  };

  render() {
    return (
      <Router history={this.history}>
        <div>
          <Switch>
            <Route path="/login" exact>
              <LoginForm
                onSubmit={this.handleLogin}
                message={this.state.message}
                useSaml={this.state.useSaml}
              />
            </Route>
            <Route path="/saml_auth" exact>
              <SamlLogin setUser={this.setUser} />
            </Route>
            <PrivateRoute path="/">
              <Nav
                links={this.getNavLinks(this.state.role)}
                user={this.state.user}
                loaded={this.state.loaded}
                isFrench={this.state.isFrench}
                onToggleLanguage={() =>
                  this.setState(({ isFrench }) => ({
                    isFrench: !isFrench
                  }))
                }
                overrideLanguage={this.state.overrideLanguage}
              />
            </PrivateRoute>
          </Switch>
          <Switch>
            <PrivateRoute exact path="/">
              <Home
                isFrench={this.state.isFrench}
                filterTicketsByDate={this.state.filterTicketsByDate}
              />
            </PrivateRoute>
            {api.permission_level("admin") && (
              <PrivateRoute path="/tag_test">
                <TagTest />
              </PrivateRoute>
            )}
            {api.permission_level("admin") && (
              <PrivateRoute path="/admin">
                <Admin />
              </PrivateRoute>
            )}
            <PrivateRoute path="/powerbi">
              <PBI />
            </PrivateRoute>
            {api.permission_level('super_user') && (
              <PrivateRoute path="/actions">
                <ActionTab isFrench={this.state.isFrench} />
              </PrivateRoute>
            )}
            {api.permission_level("super_user") && (
              <PrivateRoute path="/parts">
                <PartsTab isFrench={this.state.isFrench} />
              </PrivateRoute>
            )}
            <PrivateRoute path="/login"> </PrivateRoute>
            <Route>
              <div>
                This page does not exist yet or you do not have permission
              </div>
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}
export default Main;

const PrivateRoute = ({ children, ...rest }) => (
  <div className="container-fluid">
    <Route
      {...rest}
      render={() => (api.is_logged_in() ? children : <Redirect to="/login" />)}
    />
  </div>
);

class SamlLogin extends Component {
  state = { loggedIn: false };
  q = () => {
    const params = this.props.location.search.split("=")[1];
    console.log(params);
    return params;
  };
  //fix params on line 182 to be a string that is just the id (split on = get second)
  componentDidMount() {
    const params = this.q();
    api.saml_authenticate(params).then(({ user, role, loggedIn }) => {
      this.props.setUser(user, role, loggedIn);
      this.setState({ loggedIn: true });
    });
  }

  render() {
    if (this.state.loggedIn)
      return (
        //<div><pre>{JSON.stringify(this.q())}</pre></div>
        <Redirect to="/"></Redirect>
      );
    return "SAML Logging In";
  }
}

SamlLogin = withRouter(SamlLogin);
