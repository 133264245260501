import React, { Component } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-table/react-table.css";
import "react-toggle/style.css";
import Main from "./components/main";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);
class App extends Component {
  render() {
    return <Main />;
  }
}

export default App;
