import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import { withRouter } from "react-router-dom";

class LoginForm extends Component {
  state = {
    user: "",
    password: "",
    is_loading: false
  };
  

  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(this.state, e);
  };
  
  handleChange = e => {
    console.log(this.props.useSaml);
    const { name, value } = e.target;
    this.setState({[name]:value});
  };
  
  render() {
    return (
      <div className="cover">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 offset-sm-3 center-button">
              <Form onSubmit={this.handleSubmit}>
                {this.props.message && (
                  <Alert color="danger">{this.props.message}</Alert>
                )}
                <img alt="" src="./logo.png" className="login-logo" />

                <h1>Maintenance Portal</h1>
                {!this.props.useSaml && <FormGroup>
                  <Label for="user">User</Label>
                  <Input
                    type="text"
                    name="user"
                    id="user"
                    placeholder="jdoe"
                    onChange={this.handleChange}
                  />
                </FormGroup>}
                {!this.props.useSaml && <FormGroup>
                  <Label for="pass">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="password"
                    onChange={this.handleChange}
                  />
                </FormGroup>}
                <FormGroup>
                  <Button type="submit">Login</Button>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginForm);
