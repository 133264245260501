import React, { Component } from "react";
import api from "../utils/api";
import { TextField, StringField } from "../utils/forms";
import { Table, Container, Row, Col, Button } from "reactstrap";

class TagTest extends Component {
  state = {
    text:
      "I am some text I need to Search, Where of where is the fun in shpelling searching, if searches aren't searched.",
    search: "search, where, searching, arent, spelling",
    matches: []
  };

  tag_test = (text, search) => {
    api.tag_test(text, search).then(result => {
      this.setState({ matches: result.data });
    });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <Container fluid>
        <Row>
          <Col>
            <Container fluid>
              <Row>
                <Col>
                  <TextField
                    label="Text to be searched (media objects)"
                    id="text"
                    value={this.state.text}
                    placeholder="Enter some text to search"
                    onChange={this.handleChange}
                    rows={22}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <StringField
                    label="The words to fuzzy search for"
                    id="search"
                    value={this.state.search}
                    placeholder="Comma separated list of words to search for"
                    onChange={this.handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    onClick={() =>
                      this.tag_test(this.state.text, this.state.search)
                    }
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col>
            <Matches data={this.state.matches} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default TagTest;

function Matches({ data }) {
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>Matched Word</th>
          <th>Searched Word</th>
          <th>Likeness</th>
        </tr>
      </thead>
      <tbody>
        {data.map((v, i) => (
          <tr key={i}>
            <Match data={v} />
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function Match({ data }) {
  return (
    <>
      <th scope="row">{data.rank}</th>
      <td>{data.word}</td>
      <td>{data.tag}</td>
      <td
        style={{
          backgroundColor: data.score > 90 ? "MediumAquaMarine" : null
        }}
      >
        {data.score}
      </td>
    </>
  );
}
