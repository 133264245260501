import React, { Component } from "react";
import { Form, Container } from "reactstrap";
import { StringField, Buttons } from "../utils/forms";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

class AddPartForm extends Component {
  state = {
    part_name: ""
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit && this.props.onSubmit(this.state.part_name);
  };

  handleCancel = e => {
    if (this.props.onCancel) this.props.onCancel(this.state);
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <StringField
          id="part_name"
          label="Part"
          value={this.state.part_name}
          placeholder="Enter your new part"
          onChange={this.handleChange}
        />
        <Buttons onCancel={this.handleCancel} onSubmit={this.handleSubmit} />
      </Form>
    );
  }
}

export default function ModalActionForm(props) {
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCancel} size="lg">
      <ModalHeader>Add Part</ModalHeader>
      <ModalBody>
        <Container>
          <AddPartForm onCancel={props.onCancel} onSubmit={props.onSubmit} />
        </Container>
      </ModalBody>
    </Modal>
  );
}
