import React from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";

export function NumberInput(props) {
  return (
    <FormGroup>
      <Label for="plug">{props.label}</Label>
      <Input
        type="number"
        name={props.id}
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </FormGroup>
  );
}

export function TextField(props) {
  return (
    <FormGroup>
      <Label for="notes">{props.label}</Label>
      <Input
        type="textarea"
        name={props.id}
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        rows={props.rows || 1}
      />
    </FormGroup>
  );
}

export function StringField(props) {
  return (
    <FormGroup>
      <div className="mr-sm-2">
        <Label for={props.id} className="mr-sm-2">
          {props.label}
        </Label>
      </div>
      <div className="mr-sm-2">
        <Input
          type="text"
          name={props.id}
          id={props.id}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
        />
      </div>
    </FormGroup>
  );
}

export function StaticField(props) {
  return (
    <FormGroup>
      <Label for="priced_pnl">{props.label}</Label>
      <Input
        disabled
        type="number"
        name={props.id}
        id={props.id}
        placeholder={props.value}
      />
    </FormGroup>
  );
}

export function Buttons(props) {
  return (
    <FormGroup className="right">
      <Button onClick={props.onCancel}> Cancel </Button>{" "}
      <Button onClick={props.onSubmit}> Save </Button>{" "}
    </FormGroup>
  );
}
