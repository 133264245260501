import React from "react";
import NumberFormat from "react-number-format";
import Moment from "react-moment";

const regularNumber = row => (
  <NumberFormat
    value={row.value}
    thousandSeparator={true}
    displayType="text"
    decimalScale={0}
  />
);

const regularColoredNumber = row => (
  <NumberFormat
    value={row.value}
    thousandSeparator={true}
    displayType="text"
    decimalScale={0}
    className={classColor(Math.round(row.value))}
  />
);
const dollarNumber = row => (
  <NumberFormat
    value={row.value}
    thousandSeparator={true}
    prefix="$"
    displayType="text"
    decimalScale={0}
  />
);

const dateTime = row => (
  <Moment interval={0} date={row.value} format="YYYY-MM-DD h:mm A" />
);

const timeOfDay = row => (
  <Moment interval={0} date={row.value} format="h:mm A" />
);
const dateOnly = row => (
  <Moment interval={0} date={row.value} format="YYYY-MM-DD" />
);

const priceNumber = row => (
  <NumberFormat
    value={row.value}
    thousandSeparator={true}
    prefix="$"
    displayType="text"
    decimalScale={4}
    fixedDecimalScale={true}
  />
);
const colorNumber = row => (
  <NumberFormat
    value={row.value}
    thousandSeparator={true}
    prefix="$"
    displayType="text"
    className={classColor(Math.round(row.value))}
    decimalScale={0}
  />
);

const coloredPrice = row => (
  <NumberFormat
    value={row.value}
    thousandSeparator={true}
    prefix="$"
    displayType="text"
    className={classColor(Math.round(row.value * 100))}
    decimalScale={4}
    fixedDecimalScale={true}
  />
);

function classColor(value) {
  if (value < 0) return "Red";
  if (value > 0) return "Green";
  return "Black";
}

export default {
  reg: regularNumber,
  regc: regularColoredNumber,
  dol: dollarNumber,
  col: colorNumber,
  prc: priceNumber,
  cprc: coloredPrice,
  tod: timeOfDay,
  date: dateOnly,
  dt: dateTime
};
