import axios from "axios";

const store = window.localStorage;

let api = {};

api.set_handleLogout = handleLogout => {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    function(error) {
      if (error.response)
        if (error.response.status === 401) {
          store.removeItem("token");
          // handleLogout();
        }
      return Promise.reject(error.response);
    }
  );
};

function hosted_api() {
  const url = window.location.href;
  //starting on the 8th character after "http://" or "https://", find '/'
  const slash_loc = url.indexOf("/", 8);
  return url.substr(0, slash_loc + 1);
}

if (process.env.NODE_ENV === "production") {
  api.base_url = process.env.REACT_APP_API_URL;
} else {
  api.base_url = process.env.REACT_APP_API_URL;
}
console.log("api url", api.base_url);

const post = (endpoint, data) => {
  const token = store.getItem("token");
  return axios.post(`${api["base_url"]}${endpoint}`, data, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

api.is_logged_in = () => store.getItem("token") !== null;
api.logged_in_user = () => store.getItem("user") || "unknown";
api.logged_in_role = () => store.getItem("role") || "unknown";

api.permission_level = role => {
  if (store.getItem("role") === "admin" && role === "admin") {
    return true;
  } else if (
    (store.getItem("role") === "super_user" ||
      store.getItem("role") === "admin") &&
    (role === "super_user" || role === "admin")
  ) {
    return true;
  } else return false;
};

api.logout = () => {
  store.removeItem("token");
  store.removeItem("user");
  store.removeItem("role");
};

api.authenticate = ({ user, password }) => {
  return post("auth", { user: user, password: password }).then(result => {
    store.setItem("token", result.data.token);
    store.setItem("user", user);
    store.setItem("role", result.data.role);
    store.setItem("expiration", result.data.expiration);
    return result.data.token !== null;
  });
};

api.saml_authenticate = saml_id => {
  console.log(saml_id);
  return post("saml_id_auth", { saml_id: saml_id }).then(result => {
    store.setItem("token", result.data.token);
    store.setItem("user", result.data.user);
    store.setItem("role", result.data.role);
    store.setItem("expiration", result.data.expiration);
    console.log(result.data.token);
    console.log(result.data.user);
    console.log(store.getItem("user"));
    console.log(result.data.role);
    console.log(store.getItem("role"));
    return {
      loggedIn: result.data.token !== null,
      user: result.data.user,
      role: result.data.role
    };
  });
};

api.query = (name, params) => {
  return post("query", { name: name, query_params: params });
};

api.get_config = () => {
  return post("get_config", {});
};

api.job_status = () => {
  return post("job_status", {});
};

api.retag = id => {
  return post("retag", { ticket_id: id });
};

api.manual_tag_one = (ticket_id, action, part) => {
  return post("manual_tag_one", { ticket_id, action, part });
};

api.manual_tag_bulk = (ticket_ids, action, part) => {
  return post("manual_tag_many", { ticket_ids, action, part });
};

api.bqc_jobs = () => {
  return post("bqc_jobs");
};

api.queue_bqc_job = job_name => {
  return post("queue_bqc_job", { job_name: job_name });
};

api.word_associations = (tag_type, equipment_type) => {
  return post("word_associations", { tag_type, equipment_type });
};

api.delete_word_association = (
  tag_type,
  master_english,
  lookup_word,
  equipment_type
) => {
  return post("delete_word_association", {
    tag_type,
    lookup_word,
    master_english,
    equipment_type
  });
};

api.create_word_association = (
  tag_type,
  master_english,
  lookup_word,
  equipment_type
) => {
  return post("create_word_association", {
    tag_type,
    lookup_word,
    master_english,
    equipment_type
  });
};

api.delete_tag = (tag_type, master_english, equipment_type) => {
  return post("delete_tag", { tag_type, master_english, equipment_type });
};

api.create_tag = (tag_type, master_english, equipment_type) => {
  return post("create_tag", { tag_type, master_english, equipment_type });
};



api.tag_test = (text_to_search, tags) => {
  return post("tag_test", {
    text_to_search,
    tags,
    match_threshold: 60
  });
};

api.filtered_tickets = sql_filter => {
  return post("filter_tickets", { sql_filter });
};

api.save_master_french = (equipment_type, english, french) => {
  return post("set_french_translation", {
    equipment_type,
    english_word: english,
    french_word: french
  });
};

api.request_env = () => {
  return post("request_env");
};

api.upload_to_bq = () => {
  return api.queue_bqc_job("push_rat");
};

api.all_adjustments = () => {
  return api.query("all_adjustments", {});
};

api.daily_snapshots = () => {
  return api.query("daily_snapshots", {});
};
api.snapshots = () => {
  return api.query("snapshots", {});
};
api.pricing_snapshots = () => {
  return api.query("pricing_snapshots", {});
};

api.tickets = oldestTicketDate => {
  return api.query("tickets", { oldest_ticket_date: oldestTicketDate });
};

api.get_parts_list = equipment_type => {
  return api.query("part", { equipment_type: equipment_type });
};

api.get_actions_list = equipment_type => {
  return api.query("action", { equipment_type: equipment_type });
};

api.ticket_details = id => {
  return api.query("ticket_details", { ticket_id: id });
};

api.equipment_types = () => {
  return api.query("equipment_types", {});
};

api.updatePassword = (old_password, new_password) => {
  return post("update_auth", {
    old_password: old_password,
    new_password: new_password
  });
};

export default api;
