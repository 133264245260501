import React, { Component } from "react";
import {
  Card,
  Alert,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import api from "../utils/api";
import MomentR from "react-moment";
import { ListGroup, ListGroupItem } from "reactstrap";


class Admin extends Component {
  state = {
    job: {},
    snap_show: 1,
    bqc_jobs: []
  };

  taskButton = ({ label, ...others }) => (
    <ListGroupItem {...others} disabled={this.state.job.busy}>
      {label}
    </ListGroupItem>
  );
  componentDidMount() {
    this.refresh();
    this.interval = setInterval(this.refresh, 2000);
    api.bqc_jobs().then(result => this.setState({ bqc_jobs: result.data }));
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  refresh = () => {
    api.job_status().then(({ data }) => {
      this.setState({ job: data });
    });
  };

  render() {
    return (
      <Card>
        <CardBody>
          <CardTitle>Admin Page</CardTitle>
          <Container fluid>
            <Row>
              <Col>
                <Alert color="danger">
                  These operations are destructive to existing data. Use with
                  care!
                </Alert>
                <ListGroup>
                  {this.state.bqc_jobs.map((val, index) => (
                    <this.taskButton
                      key={index}
                      onClick={() => api.queue_bqc_job(val.name)}
                      label={val.label}
                    />
                  ))}
                </ListGroup>
              </Col>
              <Col className="col-8">
                <JobStatus job={this.state.job} />
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    );
  }
}

export default Admin;

function JobStatus({ job }) {
  return (
    <div>
      {job.busy ? (
        <Alert color="warning">
          Active Job, Please wait for the current job to finish
        </Alert>
      ) : (
        <Alert>No active job you can start a new one.</Alert>
      )}
      <br />
      {job.busy && <img src="wait.gif" alt="waiting..." />}
      <br />
      Run By: {job.who}
      <br />
      Job Name: {job.name}
      <br />
      Start Time:{" "}
      <MomentR interval={0} date={job.start} format="MM-DD h:mm A" />
      <h4>Log:</h4>
      <JobLog log={job.log} />
    </div>
  );
}

function JobLog({ log }) {
  if (log)
    return (
      <ul>
        {log.map((entry, index) => (
          <LogEntry entry={entry} key={index} />
        ))}
      </ul>
    );
  return null;
}

function LogEntry({ entry }) {
  const { date, message } = entry;
  return (
    <li>
      <MomentR interval={0} date={date} format="MM-DD h:mm:ss A" />: {message}
    </li>
  );
}
