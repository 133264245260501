import React, { Component } from "react";
import { Form, Button } from "reactstrap";
import { StringField } from "../utils/forms";

export default class MasterWordEditor extends Component {
  state = {
    value: this.props.defaultValue
  };

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  componentWillReceiveProps(props) {
    this.setState({ value: props.defaultValue });
  }

  render() {
    const label = "Master French";
    return (
      <Form inline style={{ marginTop: "1em" }}>
        <StringField
          label={label}
          value={this.state.value}
          onChange={this.handleChange}
        />
        <Button
          className="right"
          onClick={() => this.props.onSave(this.state.value)}
          size="sm"
        >
          Save
        </Button>
      </Form>
    );
  }
}
