import React, { Component } from "react";
import { Form, Container } from "reactstrap";
import { StringField, Buttons, StaticField } from "../utils/forms";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

class DeletePartForm extends Component {
  state = {
    part_name: ""
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit && this.props.onSubmit(this.state.part_name);
  };

  handleCancel = e => {
    if (this.props.onCancel) this.props.onCancel(this.state);
  };

  render() {
    const part = this.props.selectedField
      ? this.props.selectedField.master_english
      : "";
    return (
      <Form onSubmit={this.handleSubmit}>
        <StaticField
          id="action_to_delete"
          label={`Type the following to delete: ${part || ""}`}
        />
        <StringField
          id="part_name"
          label={`Part (Warning: The Part ${part} and all of its associations will be deleted. This cannot be undone.)`}
          value={this.state.part_name}
          placeholder="Type the name of the part to delete"
          onChange={this.handleChange}
        />
        <Buttons onCancel={this.handleCancel} onSubmit={this.handleSubmit} />
      </Form>
    );
  }
}

export default function ModalDeleteActionForm(props) {
  return (
    <Modal isOpen={props.isOpen} toggle={props.onCancel} size="lg">
      <ModalHeader>Delete Part</ModalHeader>
      <ModalBody>
        <Container>
          <DeletePartForm
            onCancel={props.onCancel}
            onSubmit={props.onSubmit}
            selectedField={props.selectedField}
          />
        </Container>
      </ModalBody>
    </Modal>
  );
}
