import React, { Component } from "react";
import { Form } from "reactstrap";
import api from "../utils/api";
import { Buttons } from "../utils/forms";
import SuggestedField from "./suggested_field";

export default class PriceOverrideForm extends Component {
  state = {
    actions: [],
    parts: [],
    selected_action: this.props.defaultAction || "",
    selected_part: this.props.defaultPart || ""
  };

  componentDidMount() {
    api
      .get_actions_list(this.props.equipmentType)
      .then(res => this.setState({ actions: res.data }));
    api
      .get_parts_list(this.props.equipmentType)
      .then(res => this.setState({ parts: res.data }));
  }

  handleChange = e => {};

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.selected_part === "" && this.state.selected_action === "")
      return;

    const action_good =
      this.state.actions
        .map(i => i.master_english)
        .indexOf(this.state.selected_action) > -1 ||
      this.state.selected_action === "";
    const part_good =
      this.state.parts
        .map(i => i.master_english)
        .indexOf(this.state.selected_part) > -1 ||
      this.state.selected_part === "";

    this.props.onSubmit &&
      action_good &&
      part_good &&
      this.props.onSubmit(this.state.selected_action, this.state.selected_part);
  };

  handleCancel = e => {
    if (this.props.onCancel) this.props.onCancel(this.state);
  };

  render() {
    return (
      <Form onSubmit={e => this.props.onSubmit(this.state, e)}>
        <SuggestedField
          label="Action"
          options={this.state.actions}
          placeholder="Type an action"
          value={this.state.selected_action}
          onSugChange={value => this.setState({ selected_action: value || "" })}
        />
        <SuggestedField
          label="Part"
          options={this.state.parts}
          placeholder="Type a part"
          value={this.state.selected_part}
          onSugChange={value => this.setState({ selected_part: value || "" })}
        />

        <Buttons onCancel={this.handleCancel} onSubmit={this.handleSubmit} />
      </Form>
    );
  }
}
