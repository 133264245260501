import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem
} from "reactstrap";
import api from "../utils/api";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import Toggle from "react-toggle";

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    const { links, user } = this.props;
    return (
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">
            <img alt="" src="./logo_small.png" className="brand-logo" />
            {this.props.isFrench ? "Portail d'entretien" : "Maintenence Portal"}
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavLinks links={links} isFrench={this.props.isFrench} />
            </Nav>
            <ul className="navbar-nav ml-auto">
              <li
                className="nav-item"
                style={{ paddingTop: "7px", paddingRight: "1em" }}
              >
                {this.props.overrideLanguage === "false" &&
                  <>
                    {this.props.isFrench ? "Français" : "English"}{" "}
                    <Toggle
                      checked={this.props.isFrench}
                      name="language"
                      value="yes"
                      onChange={this.props.onToggleLanguage}
                    />
                  </>
                }
              </li>
              {/* {loaded && (
                <li className="nav-item navbar-text">
                  As Of:{" "}
                  <Moment
                    interval={0}
                    date={loaded.last_load_date}
                    format="YYYY-MM-DD"
                  />
                </li>
              )} */}
              {user && (
                <li className="nav-item navbar-text">Logged in As: {user}</li>
              )}

              <LogoutLink />
            </ul>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
NavBar.propTypes = {
  links: PropTypes.array,
  disableLogout: PropTypes.bool
};

NavBar.defaultProps = {
  disableLogout: false
};
function NavLinks({ links, isFrench }) {
  return links.map((link, index) => (
    <RouterLink key={index} isFrench={isFrench} {...link} />
  ));
}

NavLinks.propTypes = {
  links: PropTypes.array
};

NavLinks.defaultProps = {
  links: [{}, {}, {}]
};

function RouterLink({ label, labelf, path, isFrench }) {
  return (
    <NavItem>
      <NavLink tag={Link} to={path} active={window.location.pathname === path}>
        {isFrench ? labelf : label}
      </NavLink>
    </NavItem>
  );
}

RouterLink.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string
};

RouterLink.defaultProps = {
  label: "Nowhere",
  path: "nowhere"
};

function LogoutLink() {
  return (
    <NavItem className="right">
      <NavLink tag={Link} to="/login" onClick={() => api.logout()}>
        Logout
      </NavLink>
    </NavItem>
  );
}
