import React, { Component } from "react";
import api from "../utils/api";
import {
  Button,
  Input,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import AddPartForm from "./add_part_form";
import AddAssociationForm from "./add_association_form";
import DeletePartForm from "./delete_part_form";
import MasterWordEditor from "./master_word_editor";

function stringCompare(first, second) {
  if (first.toLowerCase() < second.toLowerCase()) return -1;
  if (first.toLowerCase() > second.toLowerCase()) return 1;
  return 0;
}

class PartsTab extends Component {
  state = {
    data: [],
    selectedField: {},
    partFormVisible: false,
    deletePartFormVisible: false,
    associationFormVisible: false,
    selectedEquipmentType: "",
    equipmentTypes: []
  };

  componentDidMount() {
    this.loadEquipmentTypes();
    api.get_config().then( res => {
      this.setState({
        overrideLanguage: res.data['override_language']
      })
    })
  }

  loadEquipmentTypes = () => {
    api.equipment_types().then(res => {
      const equipmentTypes = res.data.map(v => v.equipment_type);
      const selectedEquipmentType = equipmentTypes[0];
      this.setState({ equipmentTypes, selectedEquipmentType });
      this.loadData(selectedEquipmentType);
    });
  };

  loadData = equipment_type => {
    api.word_associations("part", equipment_type).then(result => {
      if (this.state.selectedField.master_english) {
        const res = result.data.filter(
          x => x.master_english === this.state.selectedField.master_english
        );
        if (res.length)
          this.setState({ data: result.data, selectedField: res[0] });
        else this.setState({ data: result.data, selectedField: {} });
      } else {
        this.setState({ data: result.data });
      }
    });
  };

  selectField = field => {
    this.setState({ selectedField: field });
  };

  closePartForm = () => {
    this.setState({ partFormVisible: false });
  };

  closeDeletePartForm = () => {
    this.setState({ deletePartFormVisible: false });
  };

  closeAssociationForm = () => {
    this.setState({ associationFormVisible: false });
  };

  handleAddPart = part => {
    api.create_tag("part", part, this.state.selectedEquipmentType).then(() => {
      this.closePartForm();
      this.loadData(this.state.selectedEquipmentType);
    });
  };

  handleDeletePart = part => {
    api.delete_tag("part", part, this.state.selectedEquipmentType).then(() => {
      this.closeDeletePartForm();
      this.loadData(this.state.selectedEquipmentType);
    });
  };

  handleAddAssociation = assoc => {
    api
      .create_word_association(
        "part",
        this.state.selectedField.master_english,
        assoc,
        this.state.selectedEquipmentType
      )
      .then(() => {
        this.closeAssociationForm();
        this.loadData(this.state.selectedEquipmentType);
      });
  };

  handleDeleteAssociation = (tag_type, master_english, label) => {
    api
      .delete_word_association(
        tag_type,
        master_english,
        label,
        this.state.selectedEquipmentType
      )
      .then(() => {
        this.loadData(this.state.selectedEquipmentType);
      });
  };
  handleSelectEquipmentType = e => {
    this.setState({ selectedEquipmentType: e.target.value });
    this.loadData(e.target.value);
  };

  render() {
    return (
      <Container fluid>
        <Row>
          <Col xs={3}>
            <Container>
              <Col>
                {this.state.equipmentTypes.length > 1 && (
                  <Row>
                    <Input
                      type="select"
                      name="equipmentTypeSelect"
                      id="equipmentTypeSelect"
                      value={this.state.selectedEquipmentType}
                      onChange={this.handleSelectEquipmentType}
                    >
                      {this.state.equipmentTypes.map(v => (
                        <option key={v}>{v}</option>
                      ))}
                    </Input>
                  </Row>
                )}
                <Row xs={1} className="one-em-padding">
                  <Button
                    className="btn-spacey"
                    onClick={() => this.setState({ partFormVisible: true })}
                  >
                    New Part
                  </Button>
                  <Button
                    onClick={() =>
                      this.setState({ deletePartFormVisible: true })
                    }
                  >
                    Delete Part
                  </Button>
                </Row>
              </Col>
            </Container>
            <SelectList
              fields={this.state.data}
              onSelect={this.selectField}
              isFrench={this.props.isFrench}
            />
          </Col>
          <Col>
            {this.state.selectedField.master_english && (
              <div className="one-em-padding">
                <Button
                  onClick={() =>
                    this.setState({ associationFormVisible: true })
                  }
                >
                  New Association
                </Button>
                <br />
                {this.state.overrideLanguage === "false" &&
                <MasterWordEditor
                  isFrench={this.props.isFrench}
                  defaultValue={this.state.selectedField.master_french}
                  onSave={v => {
                    api
                      .save_master_french(
                        this.state.selectedEquipmentType,
                        this.state.selectedField.master_english,
                        v
                      )
                      .then(() => {
                        this.loadData(this.state.selectedEquipmentType);
                      });
                  }}
                />}
                <RightSide
                  field={this.state.selectedField}
                  onDeleteAssociation={this.handleDeleteAssociation}
                />
              </div>
            )}
          </Col>
        </Row>
        <AddPartForm
          isOpen={this.state.partFormVisible}
          onCancel={this.closePartForm}
          onSubmit={this.handleAddPart}
        />
        <DeletePartForm
          isOpen={this.state.deletePartFormVisible}
          onCancel={this.closeDeletePartForm}
          onSubmit={this.handleDeletePart}
          selectedField={this.state.selectedField}
        />
        <AddAssociationForm
          isOpen={this.state.associationFormVisible}
          onCancel={this.closeAssociationForm}
          onSubmit={this.handleAddAssociation}
          label="Association"
        />
      </Container>
    );
  }
}
export default PartsTab;

function SelectList({ fields, onSelect, isFrench }) {
  return (
    <ListGroup>
      {fields
        .sort((a, b) => {
          if (isFrench) return stringCompare(a.master_french, b.master_french);
          return stringCompare(a.master_english, b.master_english);
        })
        .map(i => (
          <ListGroupItem key={i.master_english} onClick={() => onSelect(i)}>
            {isFrench ? i.master_french : i.master_english}
          </ListGroupItem>
        ))}
    </ListGroup>
  );
}

function RightSide({ field, onDeleteAssociation }) {
  if (field.look_for === undefined) return null;
  return (
    <ListGroup className="one-em-padding">
      {field.look_for.map(i => (
        <ListGroupItem key={i}>
          <WordWithButton
            label={i}
            onDelete={() =>
              onDeleteAssociation("part", field.master_english, i)
            }
          />
        </ListGroupItem>
      ))}
    </ListGroup>
  );
}

function WordWithButton({ label, onDelete }) {
  return (
    <div>
      {label}
      <Button className="one-em-margin" size="sm" onClick={onDelete}>
        x
      </Button>
    </div>
  );
}
